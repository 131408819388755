.MuiGrid-container {
    margin-top: 5px;
}
.MuiButtonBase-root {
    margin-top: 20px;
}
.MuiTabs-flexContainer {
    justify-content: center;
}

.users-profile-form {
    padding: 22px;
}

.csv-button {
    position: relative;
    text-decoration: none;
    font-weight: 600;
    &:hover {
        color: rgb(21, 21, 117);
    }
}

.radios {
    .MuiTypography-body1 {
        font-size: 0.9rem;
    }
    .MuiFormGroup-root {
        flex-direction: row;
        margin-left: 15px;
    }
    .MuiFormControlLabel-labelPlacementBottom {
        flex-direction: row;
        margin-left: 0px;
    }
    .MuiSvgIcon-root {
        width: 0.7em;
        height: 0.7em;
    }
}



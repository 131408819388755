.Dealership,
.Users {
    .profile-name {
        margin-top: 30px;
    }

    .add-button {
        background-color: #3f51b5;
        color: white;

        &:hover {
            color: black;
        }
    }

    .MuiTab-textColorInherit {
        color: lightgray;
        opacity: 1;
        font-size: 16px;

        &:hover {
            color: white;
        }
    }

    .button-label {
        position: relative;
        display: flex;
    }
}

.dealership-profile-form,
.users-profile-form {
    .label-margin {
        margin-top: 14px;
    }

    .blue-button {
        margin-top: 5px;
        background-color: #3f51b5;
        color: white;

        &:hover {
            color: black;
        }
    }

    .margin-right {
        margin-right: 10px;
    }

    .MuiTab-textColorInherit {
        color: lightgray;
        opacity: 1;

        &:hover {
            color: white;
        }
    }
}

.toll-account {
    .blue-button {
        margin-top: 10px;
        background-color: #3f51b5;
        color: white;

        &:hover {
            color: black;
        }
    }

    .MuiTab-textColorInherit {
        color: lightgray;
        opacity: 1;

        &:hover {
            color: white;
        }
    }
}

.gateway {
    .MuiButton-outlined.Mui-disabled {
        margin-top: 10px;
        background-color: #3f51b55e;
        border-color: transparent;
        color: white;

        &:hover {
            color: black;
        }
    }

    .MuiButton-outlined {
        margin-top: 10px;
        background-color: #3f51b5;
        color: white;

        &:hover {
            color: black;
        }
    }

    .MuiTab-textColorInherit {
        color: lightgray;
        opacity: 1;

        &:hover {
            color: white;
        }
    }
}

.tempus-gateway {
    .label-margin {
        margin-top: 10px;
    }
}
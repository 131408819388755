.TopFormSearch {
    position: relative;

    .radios {
        .MuiTypography-body1 {
            font-size: 0.9rem;
        }
        .MuiFormGroup-root {
            flex-direction: row;
            margin-left: 15px;
        }
        .MuiFormControlLabel-labelPlacementBottom {
            flex-direction: row;
            margin-left: 0px;
        }
        .MuiSvgIcon-root {
            width: 0.5em;
            height: 0.5em;
        }
    }
}
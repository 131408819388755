.DealershipGroups {
    .profile-name {
        margin-top: 30px;
    }
    .add-button {
        background-color: #3f51b5;
        color: white;
        border: 1px, solid, darkgray;
        &:hover {
            color: black;
        }
    }
    .button-label {
        position: relative;
        display: flex;
    }
    .tab-label {
        display: flex;
        position: relative;
        left: 30%;
        font-size: 16px;
        span {
            opacity: 1;
            color: #ffffff;
        }
    }
    .dealership-groups-profile-form {
        padding: 26px;
        .blue-button {
            margin-top: 10px;
            background-color: #3f51b5;
            color: white;
            &:hover {
                color: black;
            }
        }
        // .MuiTab-textColorInherit {
        //     color: lightgray;
        //     opacity: 1;
        //     &:hover {
        //         color: white;
        //     }
        // }
    }
}
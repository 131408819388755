
.Users {
    .tab-label {
        display: flex;
        position: relative;
        left: 50%;
        transform: translateX(-50%);
        font-size: 16px;
        span {
            color: #ffffff;
            opacity: 0.8
        }
    }
    .MuiToolbar-regular {
        @media (min-width: 600px) {
            min-height: 48px;
        }
    }

    .radios {
        .MuiFormControlLabel-labelPlacementTop {
            flex-direction: unset;
            margin-right: 48px
        }
        .MuiTypography-body1 {
            font-size: 0.9rem;
        }
        .MuiFormGroup-root {
            flex-direction: row;
            margin-left: 15px;
        }
        .MuiFormControlLabel-labelPlacementBottom {
            flex-direction: row;
            margin-left: 0px;
        }
        .MuiSvgIcon-root {
            width: 0.7em;
            height: 0.7em;
        }
    }
}


